import { Directive, Input, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[bindinput]',
})
export class NuminputDirective {
  @Input('bindinput') inputType: string;
  constructor(private element: ElementRef) {}

  @HostListener('keydown', ['$event']) onInputChange(e) {
    var code = e.keyCode || e.which;
    var inputElement: any;
    //debugger;

    if (
      code === 13 ||
      code === 8 ||
      code === 9 ||
      code === 189 ||
      code === 187 ||
      code === 46 ||
      code === 45 ||
      code === 17 ||
      code === 18 ||
      code === 190 ||
      code === 110 ||
      (code >= 48 && code <= 57) ||
      (code >= 37 && code <= 40) ||
      (code >= 96 && code <= 105)
    ) {
      //console.log(code);

      if ((code === 190 || code === 110) && this.inputType === 'decimal') {
        //debugger;
        inputElement = this.element.nativeElement.getElementsByTagName('input')[0];
        let oc: number;
        oc = myUtils.countOccurrence(inputElement.value, '.');
        if (oc != 0) {
          //  debugger;
          e.preventDefault();
        }
      } else if ((code === 190 || code === 110) && this.inputType === 'whole') e.preventDefault();
    } else {
      //debugger;
      e.preventDefault();
    }
  }
}

const myUtils = (() => {
  /**
   *
   * @param str input string to find occurances from
   * @param char what to find from @param str
   * Returns no of occurances.
   */
  function countOccurrence(str: string, char: string): number {
    var count = 0;
    for (var i = 0; i < str.length; i++) {
      if (str[i] === char) {
        count++;
      }
    }
    return count;
  }

  return {
    countOccurrence: countOccurrence,
  };
})();
