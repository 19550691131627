import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular'

@Component({
  selector: 'app-list-printer',
  templateUrl: './list-printer.component.html',
  styleUrls: ['./list-printer.component.scss']
})
export class ListPrinterComponent implements OnInit {
  @Input('inputData') inputData : any;
  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
  }

  dismiss(data){
    this.modalCtrl.dismiss(data);
  }
}
