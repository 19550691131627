import { Injectable } from '@angular/core';
import { Milk } from "../milk/milk";
import { ConstantService } from "../services/constant.service";
import * as moment from 'moment';
import * as mathjs from 'mathjs';


declare var qz:any;
@Injectable({
  providedIn: 'root'
})
export class PrintService {
  printer:string;
  initialized:boolean = false;
  constructor(
    private con:ConstantService
    ) { 
    
  }


  init():Promise<boolean>{
    return new Promise( (resolve,reject) => {
      qz.websocket.connect().then(() => { 
        console.log('Print service connected.');
        this.initialized = true;
        resolve(true);
      });
    });
  }

  // Disconnect QZ Tray websocket
  disconnect(): void {
    qz.websocket.disconnect();
    this.initialized = false;
  }

  printSlip(data:Milk){
    if (this.con.SETTING_OBJ.printerSize === 58)
      this.printSlip58(data);
    else
      this.printSlip80(data)
  }
  
  printBill(data: Milk[]){
    if (this.con.SETTING_OBJ.printerSize === 58)
      this.printBill58(data);
    else
      this.printBill80(data)
  }

  async printSlip80(data: Milk){
    var config =  qz.configs.create(this.printer);
    var printdata = [
      '\x1B' + '\x40', 
      '\x1B' + '\x61' + '\x31', //center
      '\x1B' + '\x45' + '\x0D', // bold on
      this.con.SETTING_OBJ.dairyName  + '\x0A',
      '-------------------------------'+'\x0A',
      '\x0A',
      '\x1B' + '\x61' + '\x30', //left
      '\x1B' + '\x45' + '\x0A', //bold off
      '   '+moment(data.date,'YYYYMMDD').format('DD/MM/YYYY')+' - '+data.shift +' - '+data.serial + '\x0A',
      '   '+data.code+' - ' + data.animal+' - '+data.custName+ '\x0A',
      '   Liter: ' + ConstantService.toFormattedNumber(data.ltr,2,3).padEnd(8,' ') + 'Fat: '+ ConstantService.toFormattedNumber(data.fat).padEnd(8,' ') +'Rate: '+ ConstantService.toFormattedNumber( data.rate)+ '\x0A',
      '      LR: ' + data.lr.toString().padEnd(8,' ')+'SNF: '+ ConstantService.toFormattedNumber(data.snf).padEnd(9,' ')+'Amt: ' + ConstantService.toCurrency(data.netAmt, false) + '\x0A',
      '\x0A',
      '\x1B' + '\x61' + '\x31',
      '--------------Total-------------'+'\x0A',
      '\x1B' + '\x61' + '\x30', //left
      '   Liter: ' + ConstantService.toFormattedNumber(data.totalLtr)+'       Amt: ' + ConstantService.toCurrency(data.totalAmt,false) + '\x0A',
      '\x0A',
      '\x1B' + '\x61' + '\x31', //center
      this.con.SETTING_OBJ.customerMsg + '\x0A',
      '\x0A',
      '\x0A',
      '\x0A',
      //'\x1D' + '\x56'  + '\x31'
      '\x1D' + '\x56'  + '\x01'
      //'\x1B' + '\x69',
    ];
    //console.log(printdata.join(''));
    
    qz.print(config, printdata).then(() => {
      console.log("Sent data to printer");
    });
    
  }
 
  async printBill80(data:Milk[]){
    var config =  qz.configs.create(this.printer);
    var printdata = [
      '\x1B' + '\x40', 
      '\x1B' + '\x61' + '\x31', //center
      '\x1B' + '\x45' + '\x0D', // bold on
      this.con.SETTING_OBJ.dairyName  + '\x0A',
      '--------------------------------------'+'\x0A',
      '\x0A',
      '\x1B' + '\x61' + '\x30', //left
      '  No: '+ data[0].code + '  Name: ' + data[0].custName + '\x0A',
      '  ---------------------------------------------' + '\x0A',
      '  Date      M/E  B/C   Ltr  Fat  LR      Amount' + '\x0A',
      '  ---------------------------------------------' + '\x0A',
      '\x1B' + '\x45' + '\x0A', //bold off
      ]; //bold off
    let totalLtr:number = 0;
    let totalAmount:number = 0;
    let kgFat:number = 0;
    
    data.forEach((element,index) => {
      let dt:string = '';
      let shift:string ='';
      let sameDateFlag = false;
      //to print only fisrt date then just empty
      if(index === 0 || (data[index].date !== data[index-1].date) ){
        dt = moment(element.date,'YYYYMMDD').format('DD-MM-YY').padEnd(10,' ');
      }else{
       
        dt = dt.padEnd(10,' ');
        sameDateFlag = true;
      }

      //to print only fisrt M/E then just empty
      if(index === 0 || (data[index].shift !== data[index-1].shift) ){
        shift = element.shift.padEnd(5,' ');
      }else{
        if(!sameDateFlag){
          shift = element.shift.padEnd(5,' ');
        }
        else
          shift = shift.padEnd(5,' ');
      }

      let animal:string = element.animal.padEnd(3,' ');
      let ltr:string = ConstantService.toFormattedNumber(element.ltr,1).padStart(5, ' ');
      let fat:string = ConstantService.toFormattedNumber(element.fat,1).padStart(5, ' ');
      let lr:string = element.lr.toString().padStart(4, ' ');
      let amount:string = ConstantService.toCurrency(element.netAmt,false).padStart(13,' ');
      printdata.push('  '+ dt + shift + animal + ltr + fat + lr + amount +'\x0A');
      totalLtr += element.ltr;
      totalAmount += element.netAmt;
      kgFat += mathjs.round(element.fat * element.ltr,3); 
    });
    let avgFat = mathjs.round(kgFat / totalLtr,2);
    totalAmount = mathjs.round(totalAmount,2);
    totalLtr = mathjs.round(totalLtr,2);
    var printFooter =  [
      '\x1B' + '\x45' + '\x0D', // bold on
      '  ---------------------------------------------'+'\x0A',
      '  Total            '+ConstantService.toFormattedNumber(totalLtr,1).padStart(6, ' ')+ avgFat.toString().padStart(6,' ')+ ConstantService.toCurrency(totalAmount,false).padStart(16, ' ') +'\x0A',
      '\x0A',
      '\x1B' + '\x61' + '\x31', //center
      this.con.SETTING_OBJ.customerMsg + '\x0A',
      '\x0A',
      '\x0A',
      '\x0A',
      //'\x1D' + '\x56'  + '\x31'
      '\x1D' + '\x56'  + '\x01'
      //'\x1B' + '\x69',
    ];
    printdata = printdata.concat(printFooter);
    
    qz.print(config, printdata).then(() => {
      console.log("Sent data to printer");
    });
  }

  // Get the list of printers connected
  async getPrinters(): Promise<string[]> {
    return new Promise<string[]>( (resolve,reject) => {
      
      qz.printers.find().then(data => {
        console.log(data);
        resolve(data);
      
      }).catch(err=>console.error(err)) ;
    });
    
  }

  setPrinter(prinetrName:string){
    this.printer = prinetrName;
  }

  async printSlip58(data:Milk){
    var config =  qz.configs.create(this.printer);
    var printdata = [
      '\x1B' + '\x40', 
      '\x1B' + '\x61' + '\x31', //center
      '\x1B' + '\x45' + '\x0D', // bold on
      this.con.SETTING_OBJ.dairyName  + '\x0A',
      '-------------------------------'+'\x0A',
      '\x0A',
      '\x1B' + '\x61' + '\x30', //left
      '\x1B' + '\x45' + '\x0A', //bold off
      moment(data.date,'YYYYMMDD').format('DD/MM/YYYY')+' - '+data.shift +' - '+data.serial + '\x0A',
      data.code+' - ' + data.animal+' - '+data.custName+ '\x0A',
      'Ltr: ' + ConstantService.toFormattedNumber(data.ltr,2,3).padEnd(7,' ') + 'F: '+ ConstantService.toFormattedNumber(data.fat).padEnd(6,' ') +'R/L: '+ ConstantService.toFormattedNumber( data.rate)+ '\x0A',
      ' LR: '+data.lr.toString().padEnd(7,' ')+'S: '+ ConstantService.toFormattedNumber(data.snf).padEnd(6,' ')+'Amt: ' + ConstantService.toCurrency(data.netAmt, false) + '\x0A',
      '\x0A',
      '\x1B' + '\x61' + '\x31',
      '--------------Total-------------'+'\x0A',
      '\x1B' + '\x61' + '\x30', //left
      'Liter: ' + ConstantService.toFormattedNumber(data.totalLtr)+'   Amt: ' + ConstantService.toCurrency(data.totalAmt, false) + '\x0A',
      '\x0A',
      '\x1B' + '\x61' + '\x31', //center
      this.con.SETTING_OBJ.customerMsg + '\x0A',
      '\x0A',
      '\x0A',
      '\x0A',
      //'\x1D' + '\x56'  + '\x31'
      '\x1D' + '\x56'  + '\x01'
      //'\x1B' + '\x69',
    ];

    qz.print(config, printdata).then(() => {
      console.log("Sent data to printer");
    });
    
  }

  async printBill58(data:Milk[]){
    var config = qz.configs.create(this.printer);
    var printdata = [
      '\x1B' + '\x40', 
      '\x1B' + '\x61' + '\x31', //center
      '\x1B' + '\x45' + '\x0D', // bold on
      this.con.SETTING_OBJ.dairyName  + '\x0A',
      '-------------------------------'+'\x0A',
      '\x0A',
      '\x1B' + '\x61' + '\x30', //left
      'No: '+ data[0].code + '-' + data[0].custName + '\x0A',
      '-------------------------------' + '\x0A',
      'Date  T A   Ltr  Fat     Amount' + '\x0A',
      '-------------------------------' + '\x0A',
      '\x1B' + '\x45' + '\x0A', //bold off
      ]; //bold off
    let totalLtr:number = 0;
    let totalAmount:number = 0;
    let kgFat:number = 0;
    
    data.forEach((element,index) => {
      let dt:string = '';
      let shift:string ='';
      let sameDateFlag = false;
      //to print only fisrt date then just empty
      if(index === 0 || (data[index].date !== data[index-1].date) ){
        dt = moment(element.date,'YYYYMMDD').format('DD-MM').padEnd(6,' ');
      }else{
       
        dt = dt.padEnd(6,' ');
        sameDateFlag = true;
      }

      //to print only fisrt M/E then just empty
      if(index === 0 || (data[index].shift !== data[index-1].shift) ){
        shift = element.shift.padEnd(2,' ');
      }else{
        if(!sameDateFlag){
          shift = element.shift.padEnd(2,' ');
        }
        else
          shift = shift.padEnd(2,' ');
      }

      let animal:string = element.animal.padEnd(2,' ');
      let ltr:string = ConstantService.toFormattedNumber(element.ltr,1).padStart(5, ' ');
      let fat:string = ConstantService.toFormattedNumber(element.fat,1).padStart(5, ' ');
      let amount:string = ConstantService.toCurrency(element.netAmt,false).padStart(11,' ');
      printdata.push(dt + shift + animal + ltr + fat + amount +'\x0A');
      totalLtr += element.ltr;
      totalAmount += element.netAmt;
      kgFat += mathjs.round(element.fat * element.ltr,3); 
    });
    let avgFat = mathjs.round(kgFat / totalLtr,2);
    totalAmount = mathjs.round(totalAmount,2);
    totalLtr = mathjs.round(totalLtr,2);
    var printFooter =  [
      '\x1B' + '\x45' + '\x0D', // bold on
      '-------------------------------'+'\x0A',
      'Total    '+ConstantService.toFormattedNumber(totalLtr,1).padStart(6, ' ')+ avgFat.toString().padStart(5,' ')+ ConstantService.toCurrency(totalAmount,false).padStart(11, ' ') +'\x0A',
      '\x0A',
      '\x1B' + '\x61' + '\x31', //center
      this.con.SETTING_OBJ.customerMsg + '\x0A',
      '\x0A',
      '\x0A',
      '\x0A',
      //'\x1D' + '\x56'  + '\x31'
      '\x1D' + '\x56'  + '\x01'
      //'\x1B' + '\x69',
    ];
    printdata = printdata.concat(printFooter);
    
    qz.print(config, printdata).then(() => {
      console.log("Sent data to printer");
    });
  }

  
}
