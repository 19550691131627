import { Injectable } from '@angular/core';
import { BluetoothSerial } from "@ionic-native/bluetooth-serial/ngx";
import { ConstantService } from "./constant.service";
import { Milk } from "../milk/milk";
import * as moment from 'moment';
import * as mathjs from 'mathjs';


@Injectable({
  providedIn: 'root'
})
export class BtprintService {

  connectionStatus:any = false;


  constructor(private btSerial:BluetoothSerial, private con:ConstantService) { }

  searchBT():Promise<any>{
    return this.btSerial.list();
  }

  connectBT(address){
    return this.btSerial.connect(address);
  }

  print(printData){
    if (this.con.BT_PRINTER === null){
     
      return;
    }

    /// Used try catch due to catch any serious runtime error.
    try {

      if(this.connectionStatus === false){
        this.connectionStatus=this.connectBT(this.con.BT_PRINTER.id).subscribe(data=>{
          this.btSerial.write(printData).then(dataz=>{
            console.log("WRITE SUCCESS",dataz);
            
          },errx=>{
            console.log("WRITE FAILED",errx);
          });
        },err=>{
            console.log("CONNECTION ERROR",err);
        });
      }
      else{
        this.btSerial.write(printData).then(dataz=>{
          console.log("WRITE SUCCESS",dataz);
          
        },errx=>{
          console.log("WRITE FAILED",errx);
        });
      }
      
    } catch (error) {
      console.log(error);
    }
    
  }

  disconnect(){
    if(this.connectionStatus !== false){
      this.connectionStatus.unsubscribe();
      return true;
    }
    return true;
  }

  testPrint(address){
    let printData="Hello, This is a test \n\n\n\n Hello Test 123 123 123\n\n\n Celret Technologies \n\n\n";

    
    let xyz=this.connectBT(address).subscribe(data=>{
      this.btSerial.write(printData).then(dataz=>{
        console.log("WRITE SUCCESS",dataz);
        xyz.unsubscribe();
      },errx=>{
        console.log("WRITE FAILED",errx);
      });
    },err=>{
        console.log("CONNECTION ERROR",err);
    });

  }


  async printSlip(data:Milk){
    var printdata = [
      '\x1B' + '\x40', 
      '\x1B' + '\x61' + '\x31', //center
      '\x1B' + '\x45' + '\x0D', // bold on
      this.con.SETTING_OBJ.dairyName  + '\x0A',
      '-------------------------------'+'\x0A',
      '\x0A',
      '\x1B' + '\x61' + '\x30', //left
      '\x1B' + '\x45' + '\x0A', //bold off
      moment(data.date,'YYYYMMDD').format('DD/MM/YYYY')+' - '+data.shift +' - '+data.serial + '\x0A',
      data.code+' - ' + data.animal+' - '+data.custName+ '\x0A',
      'Ltr: ' + ConstantService.toFormattedNumber(data.ltr,2,3).padEnd(7,' ') + 'F: '+ ConstantService.toFormattedNumber(data.fat).padEnd(6,' ') +'R/L: '+ ConstantService.toFormattedNumber( data.rate)+ '\x0A',
      ' LR: '+data.lr.toString().padEnd(7,' ')+'S: '+ ConstantService.toFormattedNumber(data.snf).padEnd(6,' ')+'Amt: ' + ConstantService.toCurrency(data.netAmt, false) + '\x0A',
      '\x0A',
      '\x1B' + '\x61' + '\x31',
      '--------------Total-------------'+'\x0A',
      '\x1B' + '\x61' + '\x30', //left
      'Liter: ' + ConstantService.toFormattedNumber(data.totalLtr)+'   Amt: ' + ConstantService.toCurrency(data.totalAmt, false) + '\x0A',
      '\x0A',
      '\x1B' + '\x61' + '\x31', //center
      this.con.SETTING_OBJ.customerMsg + '\x0A',
      '\x0A',
      '\x0A',
      '\x0A',
      //'\x1D' + '\x56'  + '\x31'
      '\x1D' + '\x56'  + '\x01'
      //'\x1B' + '\x69',
    ];

    this.print(printdata.join(''));

  }

  async printBill(data:Milk[]){
    var printdata = [
      '\x1B' + '\x40', 
      '\x1B' + '\x61' + '\x31', //center
      '\x1B' + '\x45' + '\x0D', // bold on
      this.con.SETTING_OBJ.dairyName  + '\x0A',
      '-------------------------------'+'\x0A',
      '\x0A',
      '\x1B' + '\x61' + '\x30', //left
      'No: '+ data[0].code + '-' + data[0].custName + '\x0A',
      '-------------------------------' + '\x0A',
      'Date  T A   Ltr  Fat     Amount' + '\x0A',
      '-------------------------------' + '\x0A',
      '\x1B' + '\x45' + '\x0A', //bold off
      ]; //bold off
    let totalLtr:number = 0;
    let totalAmount:number = 0;
    let kgFat:number = 0;
    
    data.forEach((element,index) => {
      let dt:string = '';
      let shift:string ='';
      let sameDateFlag = false;
      //to print only fisrt date then just empty
      if(index === 0 || (data[index].date !== data[index-1].date) ){
        dt = moment(element.date,'YYYYMMDD').format('DD-MM').padEnd(6,' ');
      }else{
       
        dt = dt.padEnd(6,' ');
        sameDateFlag = true;
      }

      //to print only fisrt M/E then just empty
      if(index === 0 || (data[index].shift !== data[index-1].shift) ){
        shift = element.shift.padEnd(2,' ');
      }else{
        if(!sameDateFlag){
          shift = element.shift.padEnd(2,' ');
        }
        else
          shift = shift.padEnd(2,' ');
      }

      let animal:string = element.animal.padEnd(2,' ');
      let ltr:string = ConstantService.toFormattedNumber(element.ltr,1).padStart(5, ' ');
      let fat:string = ConstantService.toFormattedNumber(element.fat,1).padStart(5, ' ');
      let amount:string = ConstantService.toCurrency(element.netAmt,false).padStart(11,' ');
      printdata.push(dt + shift + animal + ltr + fat + amount +'\x0A');
      totalLtr += element.ltr;
      totalAmount += element.netAmt;
      kgFat += mathjs.round(element.fat * element.ltr,3); 
    });
    let avgFat = mathjs.round(kgFat / totalLtr,2);
    totalAmount = mathjs.round(totalAmount,2);
    totalLtr = mathjs.round(totalLtr,2);
    var printFooter =  [
      '\x1B' + '\x45' + '\x0D', // bold on
      '-------------------------------'+'\x0A',
      'Total    '+ConstantService.toFormattedNumber(totalLtr,1).padStart(6, ' ')+ avgFat.toString().padStart(5,' ')+ ConstantService.toCurrency(totalAmount,false).padStart(11, ' ') +'\x0A',
      '\x0A',
      '\x1B' + '\x61' + '\x31', //center
      this.con.SETTING_OBJ.customerMsg + '\x0A',
      '\x0A',
      '\x0A',
      '\x0A',
      //'\x1D' + '\x56'  + '\x31'
      '\x1D' + '\x56'  + '\x01'
      //'\x1B' + '\x69',
    ];
    printdata = printdata.concat(printFooter);
    
    this.print(printdata.join(''));
    
  }

}
