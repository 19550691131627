import { Injectable } from '@angular/core';
import { DataService } from "./data.service";
import { MilkService } from "./milk.service"
import { BehaviorSubject } from "rxjs";
import * as mathjs from "mathjs";

//cheatng type compiler for design document
declare function emit (val: any);
declare function emit (key: any, value: any);
declare function sum(val: any);

export interface Balance{
  _id:string,
  _rev:string,
  balance:number
}

@Injectable({
  providedIn: 'root'
})
export class CreditService {
  data: Balance;
  public myData: BehaviorSubject<Balance> = new BehaviorSubject<Balance>({} as Balance);


  constructor(private ds:DataService, private milkService:MilkService) { 
     
  }


  init(){
    this.getBalance();
      this.ds.db.changes({live: true, since: 'now', include_docs: true}).on('change',async  (change) => {
          
        if( change.id.startsWith("balance") ){
          if(this.data.balance !== change.doc.balance){
            this.data = change.doc;
            /**
            * logic to not update balance doc, instead sum all milkret and deduct from main balance
            */
            this.data.balance = mathjs.round(this.data.balance - (await this.milkService.getTotalMilkretAmt()),2); 
            this.myData.next(this.data);
          }
        }
      });
  }

   /**
   * Used to fetch and store design docs related to cust when constructing.
   */
  getBalance(){
    if (this.data) {
      this.myData.next(this.data);
      return;
    }
    
      this.ds.db.allDocs({
        include_docs: true,
        key: 'balance',
      }).then((result) => {
        let docs = result.rows.map(async (row) => {
          /**
           * logic to not update balance doc, instead sum all milkret and deduct from main balance
           */
          row.doc.balance = mathjs.round(row.doc.balance - (await this.milkService.getTotalMilkretAmt()),2); 
          this.data = row.doc; 
          this.myData.next(this.data);
        })
      });
    
  }

  deduct(amt:number){
    amt = mathjs.round(amt,2);
    if(this.data.balance !== null && this.data.balance !== undefined){
      this.data.balance = mathjs.round(this.data.balance-amt,2);
      this.updateBalance(this.data);
    }
  }

  deductLocal(amt:number){
    amt = mathjs.round(amt,2);
    if(this.data.balance !== null && this.data.balance !== undefined){
      this.data.balance = mathjs.round(this.data.balance-amt,2);
      this.myData.next(this.data);
    }
  }

  updateBalance(data:Balance){
    this.ds.db.put(data).then(res=>{
      this.data._rev = res.rev;
      this.myData.next(this.data);
    })
    .catch( (err) => {
      console.log(err);
    });
  }

} 