import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";

import { CodeValidator } from "../../validators/code";


import { CustomerService } from "../../services/customer.service";
import { Customer } from "../customer";

import { AppsettingService } from "../../services/appsetting.service";
import { Setting } from "../../setting";
import { ConstantService } from 'src/app/services/constant.service';

@Component({
  selector: 'app-custform',
  templateUrl: './custform.page.html',
  styleUrls: ['./custform.page.scss'],
  host:{
    '(document:keydown)' : 'handleKeyboardEvent($event)'
  }
})
export class CustformPage implements OnInit {

  @Input('customer') updateCustomer:Customer; 
  @Input('title') title:string;

  @ViewChild('autofocus') myInput:any;


  custForm: FormGroup;
  position:string = "stacked";
  submitAttempt: boolean = false;
  isUpdate:boolean = sessionStorage.getItem("updateCust") == "true" ? true : false;

  appSetting: Setting;

  constructor(
    public formBuilder: FormBuilder, 
    public cs:CustomerService, 
    public modalCtrl:ModalController,
    public CV:CodeValidator,
    public setting:AppsettingService ) { 

      this.setting.getSetting().then((res)=>{
        this.appSetting = res;
      });

  }

  ngOnInit() {

    this.custForm = this.formBuilder.group({
      code: [
        {
          value : this.isUpdate ? this.updateCustomer.code : [],
          disabled :  this.isUpdate,
        },
        Validators.compose([Validators.max(9999),Validators.min(1),Validators.required]),
        this.title == "Update Customer" ? [] : this.CV.checkCode.bind(this.CV)
      ],
      name: [
        this.isUpdate ? this.updateCustomer.name : '' ,
        Validators.compose([Validators.maxLength(30),Validators.pattern('[a-zA-Z ]*'),
        Validators.required])
      ],
      email: [
        this.isUpdate ? this.updateCustomer.email : '' ,
        Validators.compose([Validators.email])
      ],
      mobile: [
        this.isUpdate ? this.updateCustomer.mobile : '' ,
        Validators.compose([Validators.pattern('[5-9]{1}[0-9]{9}'),
        Validators.required])
      ],
      address: [
        this.isUpdate ? this.updateCustomer.address : '' ,
      ],
      sms: [
        this.isUpdate ? this.updateCustomer.sms : true ,
      ],
      lessOn: [
        this.isUpdate ? this.updateCustomer.lessOn : false ,
      ],
      lessPer: [
        this.isUpdate ? this.updateCustomer.lessPer : 2 ,
        Validators.compose([Validators.max(10),Validators.min(1),Validators.required]),
      ],
      rateChartId: [
        this.isUpdate ? this.updateCustomer.rateChartId : this.appSetting.defaultRateId,
      ],
      rateMaskBuffalo: [
        this.isUpdate ? this.updateCustomer.rateMaskBuffalo : 0.00
      ],
      rateMaskCow: [
        this.isUpdate ? this.updateCustomer.rateMaskCow : 0.00
      ]
    });

    setTimeout(() => {
      this.myInput.setFocus();
      
    }, 800);   
  }

  save(): void{
//    this.cs.createCustomer(this.custForm.value);
    this.submitAttempt = true;
    if( !this.custForm.valid){
      console.log(this.custForm.value); 
      console.log("DATA ERROR");
    }
    else{
      console.log(this.custForm.value);
      console.log("valid Data");
      let cust:Customer = {
          _id : "cust-"+this.custForm.value['code'],
          name : ConstantService.ucFirstAllWords(this.custForm.value['name']),
          email : this.custForm.value['email'],
          code : parseInt(this.custForm.value['code']),
          type : "customer",
          mobile : this.custForm.value['mobile'],
          address : ConstantService.ucFirstAllWords(this.custForm.value['address']),
          sms : this.custForm.value['sms'],
          lessOn: this.custForm.value['lessOn'],
          lessPer: this.custForm.value['lessPer'],
          rateChartId: this.custForm.value['rateChartId'],  // [change here]
          rateMaskBuffalo: parseInt(this.custForm.value['rateMaskBuffalo']),
          rateMaskCow: parseInt(this.custForm.value['rateMaskCow'])
      }
      if(!this.isUpdate){
        this.cs.createCustomer(cust);
      }
      else{
        cust._id = this.updateCustomer._id
        cust._rev = this.updateCustomer._rev;
        cust.type = this.updateCustomer.type;
        cust.code = this.updateCustomer.code;
        this.cs.updateCustomer(cust);
      }

      this.close();
    }
    //console.log(this.custForm.value);
    
  }

  close(): void{
    this.modalCtrl.dismiss();
  }

  async handleKeyboardEvent(event:KeyboardEvent){
    
    if (event.key === "F10"){
      event.preventDefault();
      this.save();
    }
  }


}  