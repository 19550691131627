import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from "./guards/auth.guard";

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },

  { 
    path: 'customer', 
    loadChildren: './customer/customer.module#CustomerPageModule',
    canActivate: [AuthGuard] 
  },
  { path: 'milk', loadChildren: './milk/milk.module#MilkPageModule', canActivate:[AuthGuard] },
  { path: 'rate', loadChildren: './pages/rate/rate.module#RatePageModule', canActivate: [AuthGuard] },
  { path: 'setting', loadChildren: './pages/setting/setting.module#SettingPageModule', canActivate: [AuthGuard] },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'signup', loadChildren: './pages/signup/signup.module#SignupPageModule' },  { path: 'report', loadChildren: './pages/report/report.module#ReportPageModule' }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
