import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { BehaviorSubject } from 'rxjs';
import { Customer } from '../customer/customer';
import { ConstantService } from './constant.service';
import * as moment from 'moment'; // moment for date operation

//cheatng type compiler for design document
declare function emit(val: any);
declare function emit(key: any, value: any);
declare function sum(val: any);

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  data: Customer[];
  dDocData: any = {} as any;
  public myData: BehaviorSubject<Customer[]> = new BehaviorSubject<Customer[]>([]);

  constructor(public ds: DataService, private con: ConstantService) {
    this.getCustomers().then((res) => {
      this.ds.db.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
        if (change.id.startsWith('cust')) {
          this.handleChange(change);
          this.myData.next(this.data);
        }
      });
    });

    this.getCustDesignDoc();
  }

  /**
   * Used to fetch and store design docs related to cust when constructing.
   */
  getCustDesignDoc() {
    return new Promise((resolve) => {
      this.ds.db
        .allDocs({
          include_docs: true,
          key: '_design/custdata',
        })
        .then((result) => {
          let docs = result.rows.map((row) => {
            this.dDocData = row.doc;
          });
          //creates empty designdoc if does not exist
          if (!this.dDocData._id || Object.keys(this.dDocData['views']).length !== 5) {
            var ddoc;
            if (this.dDocData._rev) {
              ddoc = {
                _id: '_design/custdata',
                _rev: this.dDocData._rev,
                views: {},
              };
            } else {
              ddoc = {
                _id: '_design/custdata',
                views: {},
              };
            }

            //cust_bill
            var mapFunc = (doc) => {
              if (doc.type === 'milk') {
                emit([doc.code, doc.billName], doc);
              }
            };
            ddoc = myUtils.updateDesignDoc(ddoc, 'cust_bill', mapFunc, null);

            //payment_slip
            mapFunc = (doc) => {
              if (doc.type === 'milk') {
                emit([doc.billName, doc.code, doc.custName], [doc.ltr, doc.netAmt, doc.lessAmt]);
              }
            };
            ddoc = myUtils.updateDesignDoc(ddoc, 'payment_slip', mapFunc, '_sum');

            //payment_slip_order_by_name
            mapFunc = (doc) => {
              if (doc.type === 'milk') {
                emit([doc.billName, doc.custName, doc.code], [doc.ltr, doc.netAmt, doc.lessAmt]);
              }
            };
            ddoc = myUtils.updateDesignDoc(ddoc, 'payment_slip_order_by_name', mapFunc, '_sum');

            //cust_annual_bill
            var mapFunc = (doc) => {
              if (doc.type === 'milk') {
                emit([doc.code, doc.date.substr(0, 6)], [doc.ltr, doc.amt]);
              }
            };
            ddoc = myUtils.updateDesignDoc(ddoc, 'cust_annual_bill', mapFunc, '_sum');

            //cust_daily_bill
            var mapFunc = (doc) => {
              if (doc.type === 'milk') {
                emit([doc.code, doc.billName], [doc.ltr, doc.amt]);
              }
            };
            ddoc = myUtils.updateDesignDoc(ddoc, 'cust_daily_bill', mapFunc, null);

            this.ds.db
              .put(ddoc)
              .catch((err) => {
                console.log('initial dDoc put error.' + err);
              })
              .then((res) => {
                this.ds.db.query('custdata/cust_bill', { stale: 'update_after' });
                this.ds.db.query('custdata/payment_slip', { stale: 'update_after' });
                this.ds.db.query('custdata/payment_slip_order_by_name', { stale: 'update_after' });
                this.ds.db.query('custdata/cust_annual_bill', { stale: 'update_after' });
                this.ds.db.query('custdata/cust_daily_bill', { stale: 'update_after' });
                resolve(this.getCustDesignDoc());
              });
          } else {
            resolve(this.dDocData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  /**
   * Returns payment slip object if not found then returns false
   * @param billName name of bill
   * @param orderByName true if orderbyname
   * @returns object or false
   */
  getPaymentSlip(billName: string, orderByName: boolean = false): Promise<any> {
    return new Promise(async (resolve) => {
      let resData: any[] = [];

      if (this.dDocData._id) {
        if (this.dDocData.views.payment_slip) {
          let res: any;
          let codeIndex: number;
          let nameIndex: number;
          if (orderByName) {
            res = await this.ds.db.query('custdata/payment_slip_order_by_name', {
              startkey: [billName, '', 0],
              endkey: [billName, '\ufff0', 9999],
              group: true,
              //group_level:2
            });
            codeIndex = 2;
            nameIndex = 1;
          } else {
            res = await this.ds.db.query('custdata/payment_slip', {
              startkey: [billName, 0, ''],
              endkey: [billName, 9999, '\ufff0'],
              group: true,
              //group_level:2
            });
            codeIndex = 1;
            nameIndex = 2;
          }

          if (res.length !== 0) {
            let totalLtr: number = 0;
            let totalLess: number = 0;
            let totalAmt: number = 0;
            res.rows.forEach((element) => {
              let ltr = Math.round(element.value[0] * 100) / 100;
              let amt = Math.round(element.value[1] * 100) / 100;
              let lessAmt = Math.round(element.value[2] * 100) / 100;
              //amt = ((amt - (amt*2.14/100)) *100)/100; // TEMP
              resData.push({
                code: element.key[codeIndex],
                name: element.key[nameIndex],
                ltr: ConstantService.toFormattedNumber(ltr, 2),
                less: ConstantService.toCurrency(lessAmt, false),
                amount: ConstantService.toCurrency(amt, false),
                sign: '',
                //below extra field used to send unformatted data for tally export
                grossAmt: Math.round((lessAmt + amt) * 100) / 100, //for purchse voucher export
                lessAmt: lessAmt,
                litre: ltr,
              });
              totalAmt += amt;
              totalLtr += ltr;
              totalLess += lessAmt;
            });

            resData.push({
              code: '',
              name: '<strong>Total</strong>',
              ltr: '<strong>' + ConstantService.toFormattedNumber(totalLtr, 2) + '</strong>',
              less: '<strong>' + ConstantService.toCurrency(totalLess) + '</strong>',
              amount: '<strong>' + ConstantService.toCurrency(totalAmt) + '</strong>',
              sign: '',
            });
            resolve(resData);
          } else resolve(false);
        }
      } else {
        this.getCustDesignDoc().then((res) => {
          this.getPaymentSlip(billName, orderByName).then((res) => {
            resolve(res);
          });
        });
      }
    });
  }

  getCustomerBill(code: number, bill: string): Promise<any> {
    return new Promise((resolve) => {
      if (this.dDocData._id)
        if (this.dDocData.views.cust_bill) {
          this.ds.db
            .query('custdata/cust_bill', {
              key: [code, bill],
            })
            .then((res) => {
              resolve(res);
            });
        } else {
          this.getCustDesignDoc().then((res) => {
            this.getCustomerBill(code, bill).then((res) => {
              resolve(res);
            });
          });
        }
    });
  }

  getCustomerAnnualBill(code: number, startBill: string, endBill: string): Promise<any> {
    return new Promise((resolve) => {
      if (this.dDocData._id)
        if (this.dDocData.views.cust_annual_bill) {
          let resData: any[] = [];
          this.ds.db
            .query('custdata/cust_annual_bill', {
              startkey: [code, startBill],
              endkey: [code, endBill],
              group: true,
            })
            .then((res) => {
              if (res.length !== 0) {
                console.log(res);
                let totalLtr: number = 0;
                let totalAmt: number = 0;
                res.rows.forEach((element) => {
                  let ltr = Math.round(element.value[0] * 100) / 100;
                  let amt = Math.round(element.value[1] * 100) / 100;
                  const month = moment(element.key[1], 'YYYYMM').format('MMMM-YYYY');
                  resData.push({
                    period: month,
                    ltr: ConstantService.toFormattedNumber(ltr, 2),
                    price: ConstantService.toFormattedNumber(Math.round((amt / ltr) * 100) / 100, 2),
                    amount: ConstantService.toCurrency(amt, false),
                  });
                  totalAmt += amt;
                  totalLtr += ltr;
                });

                resData.push({
                  period: '<strong>Total</strong>',
                  ltr: '<strong>' + ConstantService.toFormattedNumber(totalLtr, 2) + '</strong>',
                  price: '<strong>' + Math.round((totalAmt / totalLtr) * 100) / 100 + '</strong>',
                  amount: '<strong>' + ConstantService.toCurrency(totalAmt) + '</strong>',
                });
                resolve(resData);
              } else resolve(false);
            });
        } else {
          this.getCustDesignDoc().then((res) => {
            this.getCustomerAnnualBill(code, startBill, endBill).then((res) => {
              resolve(res);
            });
          });
        }
    });
  }

  /**
   * used to return filtered customer data based on name
   * @param searchTerm customer name
   */
  filterCustomer(searchTerm: string) {
    return this.data.filter((item) => {
      return item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
    });
  }

  /**
   * check customer code against database either available or not?
   * used for async validator in customer.page.ts
   * @param input string used to check for which customer
   */
  checkCodes(input: string): Promise<any> {
    if (this.data) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i]._id == 'cust-' + input) {
          return Promise.resolve({ 'Code already added': true });
        }
      }
      return Promise.resolve(null);
    }

    return new Promise((resolve) => {
      this.ds.db
        .allDocs({
          startkey: 'cust',
          endkey: 'cust\ufff0',
        })
        .then((result: any) => {
          let tempData = [];

          let docs = result.rows.map((row) => {
            tempData.push(row.id);

            tempData.forEach((element) => {
              if ('cust-' + input == element) {
                resolve({
                  'code already added': true,
                });
                //  console.log(element);
              }
            });
          });
          resolve(null);
        })
        .catch((error: any) => {
          console.log(error);
        });
    });
  }

  getCustomers(): Promise<Customer[]> {
    if (this.data) {
      this.myData.next(this.data);
      return Promise.resolve(this.data);
    } else {
      return new Promise((resolve) => {
        this.ds.db
          .allDocs({
            include_docs: true,
            startkey: 'cust',
            endkey: 'cust\ufff0',
          })
          .then((result) => {
            this.data = [];
            let docs = result.rows.map((row) => {
              this.data.push(row.doc);
            });
            resolve(this.data);
            this.myData.next(this.data);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  }

  createCustomer(customer: Customer) {
    this.ds.db.post(customer);
  }

  updateCustomer(customer) {
    this.ds.db.put(customer).catch((err) => {
      console.log(err);
    });
  }

  deleteCustomer(customer) {
    this.ds.db.remove(customer).catch((err) => {
      console.log(err);
    });
  }

  /**
   *
   * @param custCode
   * @returns Promise with detail of Customer
   */
  getSingleCustomer(custCode: number): Promise<Customer> {
    //debugger;
    if (this.data) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i]._id == 'cust-' + custCode) {
          return Promise.resolve(this.data[i]);
        }
      }
      return Promise.resolve(null);
    } else {
      return new Promise<Customer>((resolve) => {
        this.getCustomers().then((res) => {
          this.getSingleCustomer(custCode).then((res) => {
            resolve(res);
          });
        });
      });
    }
  }

  handleChange(change) {
    let changedDoc = null;
    let changedIndex = null;

    this.data.forEach((doc, index) => {
      if (doc._id === change.id) {
        changedDoc = doc;
        changedIndex = index;
      }
    });

    //A document was deleted
    if (change.deleted) {
      this.data.splice(changedIndex, 1);
    } else {
      //A document was updated
      if (changedDoc) {
        this.data[changedIndex] = change.doc;
      }

      //A document was added
      else {
        this.data.push(change.doc);
      }
    }
  }
}

/**
 * Utilities for helper methods
 */
const myUtils = (() => {
  function updateDesignDoc(ddoc: any, name: string, mapFunction: any, reduceFunc: any) {
    if (reduceFunc === null) {
      ddoc.views[name] = { map: mapFunction.toString() };
    } else {
      ddoc.views[name] = { map: mapFunction.toString(), reduce: reduceFunc.toString() };
    }
    return ddoc;
  }

  return {
    updateDesignDoc: updateDesignDoc,
  };
})();
