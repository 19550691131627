import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from "../../directives.module";

import { CustformPage } from './custform.page';
import { CodeValidator } from "../../validators/code";


const routes: Routes = [
  {
    path: '',
    component: CustformPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    DirectivesModule
  ],
  providers:[
    CodeValidator
  ],
  declarations: [CustformPage]
})
export class CustformPageModule {}
