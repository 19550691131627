import { Injectable } from '@angular/core';
import PouchDB from 'pouchdb';
//import * as jwt from "jwt-simple";
//import { Storage } from "@ionic/storage";;
import { ConstantService } from './constant.service';

//import PouchDbFind from "pouchdb-find";
//import PouchDbDebug from "pouchdb-debug";
import cordovaSqlitePlugin from 'pouchdb-adapter-cordova-sqlite';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  db: any;
  remote: any;

  constructor(private con: ConstantService) {
    //  PouchDB.plugin(PouchDbDebug);
    //  PouchDB.debug.disable();

    // To use below fire following command first
    // npm install pouchdb-adapter-cordova-sqlite --save
    // npm install cordova-sqlite-storage --save
    //
    /* PouchDB.plugin(cordovaSqlitePlugin);
    this.db = new PouchDB('mcs.db', {
      adapter: 'cordova-sqlite',
      location: 'default',
      androidDatabaseImplementation: 2,
      androidDatabaseProvider: 'system',
      auto_compaction: true
    } as any); */

    this.init();
  }

  init() {
    console.log(this.con.AUTH_OBJ);
    let details = this.con.AUTH_OBJ;
    if (details === null) {
      return;
    }
    this.remote = details.userDBs.mcs;
    let options = {
      live: true,
      retry: true,
      continuous: true,
    };

    if (this.con.PLATFORM == ConstantService.PLATFORM_MOBILE) {
      PouchDB.plugin(cordovaSqlitePlugin);
      this.db = new PouchDB('mcs' + this.con.AUTH_OBJ.user_id + '.db', {
        adapter: 'cordova-sqlite',
        location: 'default',
        androidDatabaseProvider: 'system',
        auto_compaction: true,
      } as any);
    } else {
      this.db = new PouchDB('mcs' + this.con.AUTH_OBJ.user_id, {
        auto_compaction: true,
      });
    }

    this.db.sync(this.remote, options);
    this.db.setMaxListeners(20);
    this.db.on('error', function (err) {
      debugger;
    });
    //return Promise.resolve(null);
    return;
  }
}
