import { Injectable } from '@angular/core';
import { DataService } from "../services/data.service";
import { Setting } from "../setting";

@Injectable({
  providedIn: 'root'
})
export class AppsettingService {


  private data:Setting;

  constructor(
    private ds: DataService
  ) { 
    
  } 


  getSetting():Promise<Setting> {
    if(this.data){
      sessionStorage.setItem("language",this.data.language);
      sessionStorage.setItem("_rev",this.data._rev);
      sessionStorage.setItem("defaultLr",  this.data.defaultLr.toString() );
      return Promise.resolve(this.data);
    }
    else{
      if(this.ds.db === undefined)
        return Promise.resolve(null);
      return new Promise(resolve => {
        this.ds.db.allDocs({
       
          include_docs: true,
          startkey: 'setting',
          endkey: 'setting\ufff0'
    
        }).then((result) => {
    
          this.data = {} as Setting;
    
          let docs = result.rows.map((row) => {
            this.data = row.doc;
            sessionStorage.setItem("language",this.data.language);
            sessionStorage.setItem("_rev",this.data._rev);
            sessionStorage.setItem("defaultLr",  this.data.defaultLr.toString() );
          });
          
          resolve(this.data);
        }).catch((error) => {

          console.log(error);
        });
      });
    }
  }

  setSetting(set: Setting){
    this.ds.db.put(set).catch( (err) => {
      
      console.log(err);
    });
  }

}
