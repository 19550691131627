import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import {
  IonRouterOutlet,
  ActionSheetController,
  ModalController,
  Platform,
  MenuController,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';

import { Plugins } from '@capacitor/core';

import { TranslateService } from '@ngx-translate/core';

import { AppsettingService } from './services/appsetting.service';
import { Setting } from './setting';
import { AuthenticationService } from './services/authentication.service';
import { ConstantService } from './services/constant.service';
import { LanguageComponent } from './components/language/language.component';
import { PrintService } from './services/print.service';
import { CreditService } from './services/credit.service';
import { BtprintService } from './services/btprint.service';
import { ListPrinterComponent } from './components/list-printer/list-printer.component';

const { SplashScreen, StatusBar, Browser } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
  public appPages = [
    {
      title: 'Customer',
      url: '/customer',
      icon: 'list',
    },
    {
      title: 'Milk Collection',
      url: '/milk',
      icon: 'beaker',
    },
    {
      title: 'Report',
      url: '/report',
      icon: 'analytics',
    },
  ];

  setting: Setting = {} as Setting;
  applanguage: string;
  authenticated: boolean = false;
  balance: number;
  // set up hardware back button event.
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;

  constructor(
    private authenticationService: AuthenticationService,
    private _translate: TranslateService,
    private _appsetting: AppsettingService,
    private platform: Platform,
    private modalCtrl: ModalController,
    private menu: MenuController,
    private actionSheetCtrl: ActionSheetController,
    private popoverCtrl: PopoverController,
    private router: Router,
    private toast: ToastController,
    private storage: Storage,
    private conService: ConstantService,
    private printService: PrintService,
    private creditService: CreditService,
    private btprintService: BtprintService
  ) {
    this.initializeApp();
    // Initialize BackButton Eevent.
    this.backButtonEvent();
  }

  ngOnInit() {
    this.initTranslate();
  }

  initTranslate() {
    //console.log(this.setting);
    //set the default language for translation strings, and the current time.
    this._translate.setDefaultLang(this.applanguage);
    this._translate.use(this.applanguage);
  }

  initializeApp() {
    SplashScreen.hide().catch((err) => {
      console.warn(err);
    });
    StatusBar.hide().catch((err) => {
      console.warn(err);
    });

    // When first time loads app then pouchdb does not filled so it stores default lang.
    if (sessionStorage.getItem('language') === null) {
      this.applanguage = 'en';
    } else {
      this.applanguage = sessionStorage.getItem('language');
    }

    this.platform.ready().then(async () => {
      this.authenticationService.authenticationState.subscribe((state) => {
        this.authenticated = state;
        if (!state) {
          this.router.navigate(['login']);
        } else {
          this.creditService.init();
          this.creditService.myData.subscribe((res) => {
            this.balance = res.balance;
          });
        }
      });

      //fills this.setting with setting got from server
      this.setting = await this._appsetting.getSetting();
      console.log(this.setting);
      this.conService.SETTING_OBJ = this.setting;

      if (!(this.platform.is('cordova') || this.platform.is('mobileweb') || this.platform.is('mobile'))) {
        this.conService.PLATFORM = ConstantService.PLATFORM_DESKTOP;
        var head = document.getElementsByTagName('head')[0];
        var js = document.createElement('script');
        js.type = 'text/javascript';
        js.src = './assets/js/qz-tray.js';
        head.appendChild(js);

        var js1 = document.createElement('script');
        js1.type = 'text/javascript';
        js1.src = './assets/js/rsvp-3.1.0.min.js';
        head.appendChild(js1);

        var js2 = document.createElement('script');
        js2.type = 'text/javascript';
        js2.src = './assets/js/sha-256.min.js';
        head.appendChild(js2);

        setTimeout(() => {
          this.setPrinters();
        }, 1000);
      } else {
        this.conService.PLATFORM = ConstantService.PLATFORM_MOBILE;
        let btPrinter = await this.storage.get('btPrinter');

        this.conService.BT_PRINTER = btPrinter;
      }
    });
  }

  /**
   * Used to recharge main balance
   */
  async doRecharge() {
    await Browser.open({ url: 'http://capacitor.ionicframework.com/', windowName: '_blank' });
    let t = Browser.addListener('browserFinished', (info: any) => {
      console.log(info);
    });
  }

  start() {
    this.router.navigate(['customer']);
  }

  setPrinters() {
    //BELOW IS TEMP SOLUTION FOR PRINTER SETTING
    //SHOULD BE CHANGED TO SET IN SETTING PAGE WHEN SETTING PAGE READY
    if (this.setting.slipPrinter === '' || this.setting.slipPrinter === undefined) {
      if (!this.printService.initialized) {
        this.printService.init().then((data) => {
          this.printService.getPrinters().then((data) => {
            this.setting.printerList = data;
            this._appsetting.setSetting(this.setting);
          });
        });
      } else {
        this.printService.getPrinters().then((data) => {
          this.setting.printerList = data;
          this._appsetting.setSetting(this.setting);
        });
      }
    } else {
      this.printService.setPrinter(this.setting.slipPrinter);
      this.printService.init();
    }
    //******************************************* */
  }

  /**
   *
   * @param ev this is from language segment element in footer
   * this function is used to change language setting
   */
  segmentChanged(lang: string) {
    this.setting.language = lang; //ev.srcElement.attributes[0].nodeValue;
    this.storage.set(ConstantService.LANG_KEY, this.setting.language);
    this.conService.LANG = this.setting.language;
    this._appsetting.setSetting(this.setting);
    // console.log(ev);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  /**
   * logout app and remove token from database and local storage
   */
  logout() {
    this.authenticationService.logout().then((logoutState) => {
      if (!logoutState) {
        this.toast
          .create({
            message: "Internet connection not available, Can't logout.",
            duration: 2000,
          })
          .then((toast) => {
            toast.present();
            console.log(JSON.stringify(toast));
          });
      }
    });
  }

  async openSetting() {
    let popover = await this.popoverCtrl.create({
      component: LanguageComponent,
      translucent: true,
    });
    popover.present();
    popover.onDidDismiss().then((res) => {
      if (res.data !== undefined) this.segmentChanged(res.data.lang);
    });
  }

  async openBtPrinterSetting() {
    if (!(this.conService.PLATFORM === ConstantService.PLATFORM_MOBILE)) return;

    let list = await this.btprintService.searchBT();

    const modal = await this.modalCtrl.create({
      component: ListPrinterComponent,
      componentProps: {
        inputData: list,
      },
    });
    modal.present();
    modal.onDidDismiss().then((res) => {
      if (res.data !== undefined) {
        this.storage.set('btPrinter', res.data);
        this.conService.BT_PRINTER = res.data;
        this.btprintService.testPrint(res.data.id);
      }
    });
  }

  // active hardware back button
  backButtonEvent() {
    this.platform.backButton.subscribe(async () => {
      // close action sheet
      try {
        const element = await this.actionSheetCtrl.getTop();
        if (element) {
          element.dismiss();
          return;
        }
      } catch (error) {}

      // close popover
      try {
        const element = await this.popoverCtrl.getTop();
        if (element) {
          element.dismiss();
          return;
        }
      } catch (error) {}

      // close modal
      try {
        const element = await this.modalCtrl.getTop();
        if (element) {
          element.dismiss();
          return;
        }
      } catch (error) {
        console.log(error);
      }

      // close side menua
      try {
        const element = await this.menu.getOpen();
        if (element !== null && element !== undefined) {
          this.menu.close();
          console.log('close side menu fired');
          return;
        }
      } catch (error) {}

      this.routerOutlets.forEach((outlet: IonRouterOutlet) => {
        if (outlet && outlet.canGoBack()) {
          outlet.pop();
        } else {
          //if (this.router.url === '/home') {
          if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {
            // this.platform.exitApp(); // Exit from app after bluetooth printer disconnection
            if (this.btprintService.disconnect()) navigator['app'].exitApp(); // work for ionic 4
          } else {
            this.toast
              .create({
                message: 'Press again to exit App.',
                duration: 2000,
              })
              .then((toast) => {
                toast.present();
                console.log(JSON.stringify(toast));
              });
            this.lastTimeBackPress = new Date().getTime();
          }
        }
      });
    });
  }
}
