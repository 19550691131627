import { Injectable } from '@angular/core';
import { Setting } from '../setting';
import * as moment from 'moment'; // moment for date operation

const myUtils = (() => {
  /**
   *
   * @param date date in 'YYYYMMDD' format
   * @param payInMonth no of payments made in month
   */
  function getBillName(date: string, payInMonth: number): string {
    let dt = moment(date, 'YYYYMMDD');
    //let month = dt.month()+1;
    if (payInMonth === 1) {
      return dt.format('YYM') + '1';
    } else if (payInMonth === 2) {
      if (dt.date() > 15) return dt.format('YYM') + '2';
      else return dt.format('YYM') + '1';
    } else if (payInMonth === 3) {
      if (dt.date() > 20) return dt.format('YYM') + '3';
      else if (dt.date() > 10) return dt.format('YYM') + '2';
      else return dt.format('YYM') + '1';
    } else return 'celret';
  }

  /**
   * return current financial year based on date provided
   * @param date date in 'YYYYMMDD' format
   */
  function getFinancialYearName(date: string): string {
    let dt = moment(date, 'YYYYMMDD');

    if (dt.month() >= 3) {
      let curYear = dt.year();
      let nextYear = dt.year() + 1;
      return curYear + '-' + nextYear;
    } else {
      let curYear = dt.year() - 1;
      let nextYear = dt.year();
      return curYear + '-' + nextYear;
    }
  }

  /**
   * get date range of bill
   * @param billName name of bill
   * @param payInMonth number pay in month
   * @returns {start:string,end:string}
   *
   * */
  function getDateRange(billName: string, payInMonth: number) {
    let sDate: any;
    let eDate: any;
    let momentInit = billName.substr(0, billName.length - 1);
    let initFormat = 'YYM';
    if (payInMonth === 1) {
      sDate = moment(momentInit, initFormat).startOf('month').format('DD-MM-YYYY');
      eDate = moment(momentInit, initFormat).endOf('month').format('DD-MM-YYYY');
      return { start: sDate, end: eDate };
    }

    if (payInMonth === 2) {
      if (billName.endsWith('1')) {
        eDate = moment(momentInit, initFormat).date(15).format('DD-MM-YYYY');
        sDate = moment(momentInit, initFormat).format('DD-MM-YYYY');
        return { start: sDate, end: eDate };
      }

      eDate = moment(momentInit, initFormat).endOf('month').format('DD-MM-YYYY');
      sDate = moment(momentInit, initFormat).date(16).format('DD-MM-YYYY');
      return { start: sDate, end: eDate };
    }

    if (payInMonth === 3) {
      if (billName.endsWith('1')) {
        eDate = moment(momentInit, initFormat).date(10).format('DD-MM-YYYY');
        sDate = moment(momentInit, initFormat).format('DD-MM-YYYY');
        return { start: sDate, end: eDate };
      }
      if (billName.endsWith('2')) {
        eDate = moment(momentInit, initFormat).date(20).format('DD-MM-YYYY');
        sDate = moment(momentInit, initFormat).date(11).format('DD-MM-YYYY');
        return { start: sDate, end: eDate };
      }
      eDate = moment(momentInit, initFormat).endOf('month').format('DD-MM-YYYY');
      sDate = moment(momentInit, initFormat).date(21).format('DD-MM-YYYY');
      return { start: sDate, end: eDate };
    }
  }

  // helper function to sum all the values of a specified field in an array of objects
  function sumField(arr, field) {
    return arr.reduce(function (prev, cur) {
      return prev + cur[field];
    }, 0);
  }

  return {
    getBillName: getBillName,
    getFinancialYearName: getFinancialYearName,
    getDateRange: getDateRange,
    sumField: sumField,
  };
})();

@Injectable({
  providedIn: 'root',
})
export class ConstantService {
  public PLATFORM: string;
  public AUTH_OBJ: any;
  public SETTING_OBJ: Setting;
  public LANG: string;
  public BT_PRINTER: any = undefined;

  public static TOKEN_KEY = 'auth_token';

  public static JWT_KEY = 'bal_mcs9145';
  public static LANG_KEY = 'app_lang';
  public static NODE_API_URL = 'https://host.celret.com:3000/';
  public static DATABASE_ENDPOINT = 'https://couchdb.celret.com/';
  public static RATE_TYPE_FLAT = 1;
  public static RATE_TYPE_CHART = 0;
  public static PLATFORM_MOBILE = 'mobile';
  public static PLATFORM_DESKTOP = 'desktop';
  public static DEBOUNCE_TIME = 500;
  public static SMS_SERVICE_ENDPOINT = 'https://www.fast2sms.com/dev/bulk';
  public static SMS_SENDER_ID = 'FSTSMS';
  public static MORNING_SHIFT = 'AM';
  public static EVENING_SHIFT = 'PM';
  public static DEFAULT_SETTING = {
    _id: 'setting',
    language: 'en',
    dairyName: 'Roshni Dairy - Yavarpura',
    mobile: '9537718497',
    rateIds: ['1-banas_guj'],
    defaultRateId: '1-banas_guj',
    defaultLr: 25,
    maskLr: 6,
    defaultSnfCow: 8.5,
    defaultSnfBuffalo: 9,
    lessOn: false,
    milkRetAmt: 'Math.trunc( ( ltr<25 ? 0.25 : (ltr>90 ? 0.90 : ltr*0.01) ) * 100) / 100',
    deductMilkRetAmtFromCust: true,
    payInMonth: 3,
    avgFatCalcDays: 5,
    smsAmt: 0.2,
    smsOn: true,
    roundFat: true,
    snfFormula: 'Math.round( ((lr/4)+(fat*0.21)+0.14) * 100)/100',
    calcFormulaCow:
      'Math.round( (fat+5.67) * ( Math.trunc( ( (rate- (rate*2) /100)*1.02 )   *  100)/100 - ((defaultCowSNF-snf)*100) )/100 * 100)/100',
    calcFormulaBuffalo:
      'Math.round( (fat* ( Math.trunc( ( (rate- (rate*2) /100)*1.02 ) *100)/100 - ((defaultBuffaloSNF-snf)*100) )/100) * 100)/100',
    printSlip: true,
    customerMsg: 'Software By: Celret Technologies!',
    printerList: [],
    slipPrinter: '',
    printerSize: 80,
    smsServiceAuthToken: 'uAr0HtOZCIb8zeD1Bp79XxNws3JGg6PdaQWSqKiRkjV4L5hyEn0D3omXUqS6re8RhYaHpbJWFgnNKPuL',
  };
  public util = myUtils;
  constructor() {}
  public static ucFirstAllWords(str) {
    var pieces = str.split(' ');
    for (var i = 0; i < pieces.length; i++) {
      var j = pieces[i].charAt(0).toUpperCase();
      pieces[i] = j + pieces[i].substr(1).toLowerCase();
    }
    return pieces.join(' ');
  }

  public static toCurrency(x: number, symbol: boolean = true): string {
    if (symbol) return x.toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
    return x.toLocaleString('en-IN', { minimumFractionDigits: 2 });
  }

  public static toFormattedNumber(x: number, fraction: number = 2, maxFraction: number = fraction): string {
    return x.toLocaleString('en-IN', { minimumFractionDigits: fraction, maximumFractionDigits: maxFraction });
  }
}
