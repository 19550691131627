import { FormControl } from "@angular/forms";
import { Injectable } from "@angular/core";
import { CustomerService } from "../services/customer.service";

@Injectable()
export class CodeValidator {

    debouncer:any;
    
    constructor(public custService:CustomerService){
        
    }


     /****
     * Checks weather code available or not
     * @return promise null if code not added or object with detail
     * 
     * 
     */
    checkCode(control:FormControl):any {

        if(isNaN(control.value)){
            return{
                "not a number": true
            };
        }
        if(control.value % 1 !== 0 ){
            return{
                "not a whole number":true
            };
        }
        if(control.value < 1){
            return{
                "not a positive number":true
            };

        }

        clearTimeout(this.debouncer);

        return new Promise(resolve => {
            this.debouncer = setTimeout(() => {
                this.custService.checkCodes(control.value).then((res) => {
                    resolve(res);
                });
            }, 1000);
        });
    }


    /****
     * Checks weather code available or not
     * @return promise null if code added or object with detail
     * 
     * 
     */
    checkMilkCode(control:FormControl):any {

        if(isNaN(control.value)){
            return{
                "not a number": true
            };
        }
        if(control.value % 1 !== 0 ){
            return{
                "not a whole number":true
            };
        }
        if(control.value < 1){
            return{
                "not a positive number":true
            };

        }

        clearTimeout(this.debouncer);

        return new Promise(resolve => {
            this.debouncer = setTimeout(() => {
                this.custService.checkCodes(control.value).then( (res) => {
                    if (res != null)
                        resolve(null);
                    else    
                        resolve({
                            "code not available":true
                        });
                });
            }, 400);
        });
    }
    
}