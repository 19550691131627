import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { TranslateModule,TranslateLoader } from "@ngx-translate/core";
import { HttpClientModule,HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { CustformPageModule } from "./customer/custform/custform.module";

import { AuthenticationService } from "./services/authentication.service";

import { IonicStorageModule } from "@ionic/storage";
import { ConstantService } from "./services/constant.service";

import { LanguageComponent } from "./components/language/language.component";
import { ListPrinterComponent } from "./components/list-printer/list-printer.component";
import { BluetoothSerial } from "@ionic-native/bluetooth-serial/ngx";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';


//import { TabindexDirective } from './directives/tabindex.directive';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}


@NgModule({
  declarations: [AppComponent, LanguageComponent, ListPrinterComponent],
  entryComponents: [LanguageComponent, ListPrinterComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode:'md'
    }),
    AppRoutingModule,
    CustformPageModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },

    {
      provide:APP_INITIALIZER,
      useFactory: (app:AuthenticationService,  con:ConstantService) => async () => {
        con.AUTH_OBJ = await app.checkToken();
        
      },  
      deps:[AuthenticationService, ConstantService],
      multi:true
    },
    BluetoothSerial
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
