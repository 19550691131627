import { Component, OnInit } from '@angular/core';
import { AppsettingService } from "../../services/appsetting.service"
import { ConstantService } from "../../services/constant.service";
import { PopoverController  } from "@ionic/angular";

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {

  appLang:string = 'en';
  constructor(
    private setting:AppsettingService,
    private popover:PopoverController,
    private con:ConstantService
  ) { 
    this.setting.getSetting().then(res => {
      this.appLang = res.language;
    })
  }

  changeLang(ev:any){
    this.popover.dismiss({lang:ev.detail.value});
  }

  ngOnInit() {
  }

}
