import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject } from 'rxjs';
import * as jwt from "jwt-simple";
import { ConstantService } from "./constant.service";



 
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
 
  authenticationState = new BehaviorSubject(false);
 
  constructor(
    private storage: Storage, 
    private plt: Platform, 
    private http:HttpClient, 
    private con:ConstantService
  ) {
    this.plt.ready().then(() => {
      this.checkToken();
    });
    
  }
 
  /**
   * Used to check wether ionic storage has encrypted (jwt) token available or not.
   * @resolve null if not logged in otherwise token itself
   * 
   */
  checkToken():Promise<any> {
    console.log("Check Token Called");
    return new Promise(async (resolve, reject) => {
      let keyLength = await this.storage.length();
      let token:any = null;
      let resolveFlag:boolean = false;
      let authFlag:boolean = false
      await this.storage.forEach( (value, key, index ) => {
        
        switch (key) {
          case ConstantService.LANG_KEY:
            sessionStorage.setItem('language',value);  
            break;

          case ConstantService.TOKEN_KEY:
            authFlag = true;
            token = jwt.decode(value,ConstantService.JWT_KEY);
            break;

          default:
            console.log(key+'---->'+value);
            break;
        }

        
          
        if(index === keyLength && authFlag){
          resolveFlag = true;
          console.log('resolve flag = true');
          this.authenticationState.next(true);
          resolve(token); 
        }
          
      });
      if(!resolveFlag){
        console.log("resolve flag= false");
        resolve(null);
      }
    });
  }
 
 
 /**
  * Used to clear token from ionic storage and also call
  * /auth/logout on server to remove session data from couchdb
  * if internet not available then it return Promise false
  */
  logout():Promise<boolean> {
    return new Promise((resolve) => { 
      let bearer:string = 'Bearer '+ this.con.AUTH_OBJ.token +':'+ this.con.AUTH_OBJ.password;
      
      this.http
        .post(ConstantService.NODE_API_URL+'auth/logout','', { 
          headers: new HttpHeaders().set('Authorization', bearer)
        })
        .subscribe(res => {
          console.log(res);
          this.storage.remove(ConstantService.TOKEN_KEY).then(() => {
            this.authenticationState.next(false);
          });
          resolve(true);
        }, err => {
          console.log(err);
          if(err.status === 401){
            this.storage.remove(ConstantService.TOKEN_KEY).then(() => {
              this.authenticationState.next(false);
            });
            resolve(true);
          }else{
            resolve(false);
          }
        });
    });
  
  }
 
  isAuthenticated() {
    return this.authenticationState.value;
  }
 
}