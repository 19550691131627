import { Injectable } from '@angular/core';
import { DataService } from "../services/data.service";
import { Milk } from "../milk/milk";
import { BehaviorSubject } from "rxjs";
import * as moment from "moment";
import * as mathjs from "mathjs";




//cheatng type compiler for design document
declare function emit (val: any);
declare function emit (key: any, value: any);
declare function sum(val: any);


@Injectable({
  providedIn: 'root'
})
export class MilkService {

  data: Milk[];
  myData: BehaviorSubject<Milk[]> = new BehaviorSubject<Milk[]> ([]);
  dDocData:any = {} as any;
  changeHandle:any

  constructor(private ds:DataService) { 
    
    this.getMilkDesignDoc();
  }

  init(formatedDate:string, shift:string){
    this.getMilk(formatedDate,shift).then( (res) => {
      if(this.changeHandle !== undefined)
        this.changeHandle.cancel();
        
      this.changeHandle = this.ds.db.changes({live: true, since: 'now', include_docs: true})
        .on('change', (change) => {
          
          if( change.id.startsWith("milk-"+formatedDate+shift) ){ //register for only today's milk change
            this.handleChange(change);
            this.myData.next(this.data);
          }
        });
    })

  }

  /**
   * this is initial run function in constructor to init data with milk data
   */
  getMilk(formatedDate:string,shift:string): Promise<Milk[]>{
    /* if(this.data){
      this.myData.next(this.data);
      return Promise.resolve(this.data);
    } */

    return new Promise(resolve => {
      this.ds.db.allDocs({
        include_docs: true,
        startkey: 'milk-'+ formatedDate + shift, //set tunk detail at last to get only tunk data
        endkey: 'milk-'+ formatedDate+shift +'\ufff0'
      }).then( (result) => {
        this.data = [];
        let docs = result.rows.map((row) => {
          this.data.push(row.doc);
        })
        resolve(this.data);
        this.myData.next(this.data);
      }).catch((error)=> {
        console.log(error);
      });
    })
  }


  /**
   * Used to fetch and store design docs related to milk when constructing.
   */
  getMilkDesignDoc(){
    return new Promise((resolve) => {
      this.ds.db.allDocs({
        include_docs: true,
        key: '_design/milkdata',
      }).then( (result) => {
        let docs = result.rows.map((row) => {
          this.dDocData = row.doc;
        })
        //creates empty designdoc if does not exist
        if(!this.dDocData._id || Object.keys(this.dDocData["views"]).length !== 8){
          var ddoc;
          if(this.dDocData._rev){
            ddoc = {
              _id: '_design/milkdata',
              _rev:this.dDocData._rev ,
              views: {
              }
            };
          }else{
            ddoc = {
              _id: '_design/milkdata',
              views: {
              }
            };
          }
          

           //total_amt
          var mapFunc = (doc) => {
            if(doc.type === 'milk'){
              emit([doc.billName,doc.code],doc.netAmt);
            }
          }
          ddoc = myUtils.updateDesignDoc(ddoc,'total_amt',mapFunc,'_stats');

          //total_ltr
          mapFunc = (doc) => {
            if(doc.type === 'milk'){
              emit([doc.billName,doc.code],doc.ltr);
            }
          }
          ddoc = myUtils.updateDesignDoc(ddoc,'total_ltr',mapFunc,'_stats');

          //total_milkret
          mapFunc = (doc) => {
            if(doc.type === 'milk'){
              emit(doc.type,doc.milkRetAmt);
            }
          }
          ddoc = myUtils.updateDesignDoc(ddoc,'total_milkret',mapFunc,'_sum');

          //max_serial
          mapFunc = (doc) => {
            if(doc.type === 'milk'){
              emit([doc.date,doc.shift],doc.serial);
            }
          }
          ddoc = myUtils.updateDesignDoc(ddoc,'max_serial',mapFunc,'_stats');
          //average_fat
          mapFunc = (doc) => {
            if(doc.type === 'milk'){
              emit([doc.code,doc.shift,doc.animal,doc.date],{"ltr":doc.ltr,"fat":doc.fat});
            }
          }
          var reduceFunc = (key, values) => {
            var totalLtr = 0;
            var ltrFat = 0;
            //console.log(values);
            values.forEach(element => {
              //console.log(element);
              totalLtr += element.ltr;
              ltrFat += element.ltr * element.fat;
            });
            return ltrFat / totalLtr;
          }
          ddoc = myUtils.updateDesignDoc(ddoc,'average_fat',mapFunc,reduceFunc);

          //by_cust
          mapFunc = (doc) => {
            if(doc.type === 'milk'){
              emit([doc.date, doc.shift, doc.code],doc);
            }
          }
          ddoc = myUtils.updateDesignDoc(ddoc,'by_cust',mapFunc,null);

          //single_by_cust
          mapFunc = (doc) => {
            if(doc.type === 'milk'){
              emit(doc.code,doc.code);
            }
          }
          ddoc = myUtils.updateDesignDoc(ddoc,'single_by_cust',mapFunc,'_stats');

          //cust_bill
          mapFunc = (doc) => {
            if(doc.type === 'milk'){
              emit([doc.code,doc.billName],doc);
            }
          }
          var reduceFunc = (key, values) => {
            var totalLtr = 0;
            var ltrFat = 0;
            values.forEach(element => {
              //console.log(element);
              totalLtr += element.ltr;
              ltrFat += element.ltr * element.fat;
            });
            return ltrFat / totalLtr;
          }
          ddoc = myUtils.updateDesignDoc(ddoc,'cust_bill',mapFunc,reduceFunc);


          this.ds.db.put(ddoc).catch((err)=>{
            console.log("initial dDoc put error."+ err);
          }).then((res)=>{
            this.ds.db.query('milkdata/total_amt', {stale: 'update_after'});
            this.ds.db.query('milkdata/total_ltr', {stale: 'update_after'});
            this.ds.db.query('milkdata/max_serial', {stale: 'update_after'});
            this.ds.db.query('milkdata/by_cust', {stale: 'update_after'});
            this.ds.db.query('milkdata/single_by_cust', {stale: 'update_after'});
            this.ds.db.query('milkdata/average_fat', {stale: 'update_after'});
            this.ds.db.query('milkdata/cust_bill', {stale: 'update_after'});
            this.ds.db.query('milkdata/total_milkret',{stale: 'update_after'});
            resolve( this.getMilkDesignDoc() );
          });
        }else{
          resolve(this.dDocData);
        }
      }).catch((err)=>{
        console.log(err);
      });
    });
  }


  createMilk(milk: Milk){
    this.ds.db.put(milk).catch( (err) => {
      console.log(err);
    });
  }

  updateMilk(milk: Milk){
    this.ds.db.put(milk).catch( (err) => {
      console.log(err);
    });
  }

  deleteMilk(milk: Milk):Promise<any> {
    return this.ds.db.remove(milk);
  }


  /**
   * Gets last total amount of customer.
   * @param billName to get total from this bill
   * @param code customer to which we have to get sum.
   */
  getLastTotalAmt(billName:string, code:number): Promise<any> {
    return new Promise((resolve) => { 
      
      if(this.dDocData._id){
        if(!this.dDocData.views.total_amt ){
          var mapFunc = (doc) => {
            if(doc.type === 'milk'){
              emit([doc.billName,doc.code],doc.netAmt);
            }
          }
          var designDoc = myUtils.updateDesignDoc(this.dDocData,'total_amt',mapFunc,'_stats');
          this.ds.db.put(designDoc).catch((err)=> {
            if(err.name !== 'conflict'){
              throw err;
            }
          }).then(() => {
            //used to build initial index
            return this.ds.db.query('milkdata/total_amt', {stale: 'update_after'});
          }).then(()=>{
            return this.ds.db.query('milkdata/total_amt',{
              key: [billName,code]
            });
          }).then((res) => {
            resolve(res);
          }).catch((err)=>{
            console.warn(err);
          });
        } else {
          this.ds.db.query('milkdata/total_amt',{
            key: [billName,code]
          }).then((res)=>{
            resolve(res);
          });
        }
      }else {
        this.getMilkDesignDoc().then((res)=>{
          this.getLastTotalAmt(billName,code).then((res)=> {
            resolve(res);
          })
        })
      }
    });
  }


  /**
   * Gets last total litre of customer.
   * @param billName to get total litre from this bill.
   * @param code customer no. to get total litre of
   */
  getLastTotalLtr(billName:string, code:number): Promise<any> {
    
    return new Promise((resolve) => { 
      
      if(this.dDocData._id)
        if(!this.dDocData.views.total_ltr){
          var mapFunc = (doc) => {
            if(doc.type === 'milk'){
              emit([doc.billName,doc.code],doc.ltr);
            }
          }
          var designDoc = myUtils.updateDesignDoc(this.dDocData,'total_ltr',mapFunc,'_stats');
          this.ds.db.put(designDoc).catch((err)=> {
            if(err.name !== 'conflict'){
              throw err;
            }
          }).then(() => {
            //used to build initial index
           // debugger;
            return this.ds.db.query('milkdata/total_ltr', {stale: 'update_after'});
          }).then(()=>{
            return this.ds.db.query('milkdata/total_ltr',{
              key: [billName,code]
            });
          }).then((res) => {
            resolve(res);
          }).catch((err)=>{
            console.warn(err);
          });
        } else {
          this.ds.db.query('milkdata/total_ltr',{
            key: [billName,code]
          }).then((res)=>{
            resolve(res);
          });
        }
      else {
        this.getMilkDesignDoc().then((res)=>{
          this.getLastTotalLtr(billName,code).then((res)=> {
            resolve(res);
          })
        })
      }
    });
  }


  /**
   * Gets last serial no of milk received for specific date and shift.
   * @param date YYYYMMDD date to which we want last serial no
   * @param shift 'M' or 'E' 
   */
  getLastSerialNo(date:string,shift:string) : Promise<any> {  
    return new Promise((resolve) => { 
      
      if(this.dDocData._id){
        if(!this.dDocData.views.max_serial){
          var mapFunc = (doc) => {
            if(doc.type === 'milk'){
              emit([doc.date,doc.shift],doc.serial);
            }
          }
          var designDoc = myUtils.updateDesignDoc(this.dDocData,'max_serial',mapFunc,'_stats');
          this.ds.db.put(designDoc).catch((err)=> {
            if(err.name !== 'conflict'){
              throw err;
            }
          }).then(() => {
            //used to build initial index
            return this.ds.db.query('milkdata/max_serial', {stale: 'update_after'});
          }).then(()=>{
            return this.ds.db.query('milkdata/max_serial',{
              key: [date,shift]
            });
          }).then((res) => {
            resolve(res);
          }).catch((err)=>{
            console.warn(err);
          });
        } else {
          this.ds.db.query('milkdata/max_serial',{
            key: [date,shift]
          }).then((res)=>{
            resolve(res);
          });
        }
      }else {
        this.getMilkDesignDoc().then((res)=>{
          this.getLastSerialNo(date,shift).then((res)=> {
            resolve(res);
          })
        })
      }
    });
  }


  /**
   * Returns average fat based on below parameter.
   * @param code for which avg fat being got
   * @param shift 'M' or 'E'
   * @param animal 'B' or 'C'
   * @param today last day to calculate avg fat
   * @param day how many days from last day to consider for calculation.
   */
  getAverageFat(code: number, shift: string, animal:string, today: string, day:number ) : Promise<any> {
    return new Promise((resolve) => { 
      let startDate:string = moment(today, 'YYYYMMDD').subtract(day,'days').format('YYYYMMDD');
      if(this.dDocData._id){
        if(!this.dDocData.views.average_fat){

          var mapFunc = (doc) => {
            if(doc.type === 'milk'){
              emit([doc.code,doc.shift,doc.animal,doc.date],{"ltr":doc.ltr,"fat":doc.fat});
            }
          }

          var reduceFunc = (key, values) => {
            var totalLtr = 0;
            var ltrFat = 0;
            //console.log(values);
            values.forEach(element => {
              //console.log(element);
              totalLtr += element.ltr;
              ltrFat += element.ltr * element.fat;
            });
            return ltrFat / totalLtr;
          }

          var designDoc = myUtils.updateDesignDoc(this.dDocData,'average_fat',mapFunc,reduceFunc);
          this.ds.db.put(designDoc).catch((err)=> {
            if(err.name !== 'conflict'){
              throw err;
            }
          }).then(() => {
            //used to build initial index
            return this.ds.db.query('milkdata/average_fat', {stale: 'update_after'});
          }).then(()=>{
            return this.ds.db.query('milkdata/average_fat',{
              "startkey": [ code, shift, animal, startDate ], "endkey": [ code, shift,animal, today ]
            });
          }).then((res) => {
            resolve(res);
          }).catch((err)=>{
            console.warn(err);
          });
        } else {
          this.ds.db.query('milkdata/average_fat',{
            "startkey": [ code, shift, animal, startDate ], "endkey": [ code, shift,animal, today ]
          }).then((res)=>{
            resolve(res);
          });
        }
      }else {
        this.getMilkDesignDoc().then((res)=>{
          this.getAverageFat(code,shift,animal,today,day) .then((res)=> {
            resolve(res);
          })
        })
      }
    });    
  }


  /**
   * Used to get milk detail of customer as per details specified.
   * @param date to get milk detail of this date
   * @param shift either 'M' or 'E'
   * @param code customer for which to get detail
   */
  getMilkByCustomer(date: string, shift: string, code: number): Promise<any> {
    return new Promise((resolve) => { 
        
      if(this.dDocData._id){
        if(!this.dDocData.views.by_cust){
          var mapFunc = (doc) => {
            if(doc.type === 'milk'){
              emit([doc.date, doc.shift, doc.code],doc);
            }
          }
          var designDoc = myUtils.updateDesignDoc(this.dDocData,'by_cust',mapFunc,null);
          this.ds.db.put(designDoc).catch((err)=> {
            if(err.name !== 'conflict'){
              throw err;
            }
          }).then(() => {
            //used to build initial index
            return this.ds.db.query('milkdata/by_cust', {stale: 'update_after'});
          }).then(()=>{
            return this.ds.db.query('milkdata/by_cust',{
              key: [date, shift, code]
            });
          }).then((res) => {
            resolve(res);
          }).catch((err)=>{
            console.warn(err);
          });
        } else {
          this.ds.db.query('milkdata/by_cust',{
            key: [date, shift, code]
          }).then((res)=>{
            resolve(res);
          });
        }
      }else {
        this.getMilkDesignDoc().then((res)=>{
          this.getMilkByCustomer(date, shift, code).then((res)=> {
            resolve(res);
          })
        })
      }
    });
  }



  /**
   * Returns _stats of milk data of customer wether cust has milk recorded or not
   * @param code customer code to receive milk data
   */
  getSingleMilkByCustomer(code: number): Promise<any>{
    return new Promise((resolve) => { 
        
      if(this.dDocData._id){
        if(!this.dDocData.views.single_by_cust){
          var mapFunc = (doc) => {
            if(doc.type === 'milk'){
              emit(doc.code,doc.code);
            }
          }
          var designDoc = myUtils.updateDesignDoc(this.dDocData,'single_by_cust',mapFunc,'_stats');
          this.ds.db.put(designDoc).catch((err)=> {
            if(err.name !== 'conflict'){
              throw err;
            }
          }).then(() => {
            //used to build initial index
            return this.ds.db.query('milkdata/single_by_cust', {stale: 'update_after'});
          }).then(()=>{
            return this.ds.db.query('milkdata/single_by_cust',{
              key: code
            });
          }).then((res) => {
            resolve(res);
          }).catch((err)=>{
            console.warn(err);
          });
        } else {
          this.ds.db.query('milkdata/single_by_cust',{
            key: code
          }).then((res)=>{
            resolve(res);
          });
        }
      }else {
        this.getMilkDesignDoc().then((res)=>{
          this.getSingleMilkByCustomer(code).then((res)=> {
            resolve(res);
          })
        })
      }
    });

  }



  /**
   * 
   * @param milkId full id of milk instance 
   * @returns Promise with detail of Ratechart
   *  
   */
  getSingleMilk(milkId:string): Promise<Milk> {
    
    if(this.data){
      
      for(let i=0;i<this.data.length;i++){
        if(this.data[i]._id == milkId ){
          return Promise.resolve(this.data[i]);
        }
      }
      return Promise.resolve(null);
    }
    else{
        return new Promise( (resolve) => {
        /* this.getMilk().then((res)=> {     // if called before init then init manuallly this.data
            this.getSingleMilk(milkId).then((res) => { */
              resolve(null);
            //});
          //});
      });
    }
  }

  /**
   * gets Total milkRetAmt 
   */
  getTotalMilkretAmt(): Promise<number>{
    return new Promise<number>(resolve=>{
      if(this.dDocData._id){
        this.ds.db.query('milkdata/total_milkret').then(res=>{
          resolve( mathjs.round(res.rows[0].value,2));
        });
      }else{
        this.getMilkDesignDoc().then(res=>{
          this.getTotalMilkretAmt().then(res=>{
            resolve(res);
          });
        });
      }
    });
  }


  /**
   * 
   * @param change returned from database pouch.onChange event
   */
  handleChange(change){
    let changedDoc = null;
    let changedIndex = null;

    this.data.forEach((doc, index) => {

      if(doc._id === change.id){
        changedDoc = doc;
        changedIndex = index;
      }

    });

    //A document was deleted
    if(change.deleted){
      this.data.splice(changedIndex, 1);
    } 
    else {

      //A document was updated
      if(changedDoc){
        this.data[changedIndex] = change.doc;
      } 

      //A document was added
      else {
        this.data.push(change.doc); 
      }

    }
  }


}


/**
 * Utilities for helper methods
 */
const myUtils = (() => {
  function createDesignDoc(ddocname:string,name:string,mapFunction:any,reduceFunc:any) {
    var ddoc = {
      _id: '_design/' + ddocname,
      views: {
      }
    };
    if(reduceFunc === null){
      ddoc.views[name] = { map: mapFunction.toString() };
    }
    else{
      ddoc.views[name] = { map:mapFunction.toString(), reduce:reduceFunc.toString() };
    }
    return ddoc;
  }

  function updateDesignDoc(ddoc:any,name:string,mapFunction:any, reduceFunc: any) {
    if(reduceFunc === null){
      ddoc.views[name] = { map: mapFunction.toString() };
    }
    else{
      ddoc.views[name] = { map:mapFunction.toString(), reduce:reduceFunc.toString() };
    }
    return ddoc;
  }

  return{
    createDesignDoc : createDesignDoc,
    updateDesignDoc : updateDesignDoc
  };
})(); 
