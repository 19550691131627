import { TabindexDirective } from "../app/directives/tabindex.directive";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CalendarModule } from "ion2-calendar";
import { NuminputDirective } from "../app/directives/numinput.directive";
import { LongPressDirective } from "../app/directives/long-press.directive";
import { HAMMER_GESTURE_CONFIG } from "@angular/platform-browser";
import { IonicGestureConfig } from "../app/classes/ionic-gesture-config";

@NgModule({
    imports:[TranslateModule,CalendarModule],
    declarations:[TabindexDirective, NuminputDirective, LongPressDirective],

    providers: [{provide: HAMMER_GESTURE_CONFIG, useClass: IonicGestureConfig}],
    exports: [TabindexDirective, TranslateModule, CalendarModule, NuminputDirective, LongPressDirective ]
})
export class DirectivesModule{

}